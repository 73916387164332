const ENDPOINTS = {
  AUTH: "/auth/",
};

// honoring login hints for 1 hour
// subject to change
const HINTS_EXPIRATION = 3600;

// default sso timeout
// used when starfleet class is not initiated with a timeout value
const DEFAULT_SSO_TIMEOUT = 3000;

export default class Starfleet {
  constructor(configs) {
    this.configs = configs;
  }

  authorize(ui_locales, prompt, login_hint = null) {
    localStorage.removeItem(this.configs.SESSION_ITEM_NAME);
    localStorage.removeItem(this.configs.PRIVACY_ITEM_NAME);
    const [lang, region] = ui_locales.split("-");
    const searchParams = new URLSearchParams({
      redirect_uri: location.href,
      client_id: this.configs.CLIENT_ID,
      ui_locales: `${lang}-${region.toUpperCase()}`,
    });
    if (prompt) {
      searchParams.set("prompt", prompt);
    }
    if (login_hint) {
      searchParams.set("login_hint", login_hint);
    }
    window.location.href = `${DMO_AUTH_URL}?${searchParams.toString()}`;
  }

  getLocalSession(resolve) {
    const session = JSON.parse(
      localStorage.getItem(this.configs.SESSION_ITEM_NAME)
    );
    if (session && new Date(session.expiration) > new Date()) {
      resolve(session);
      return true;
    } else return false;
  }

  getAuthHints() {
    return JSON.parse(localStorage.getItem(this.configs.LOGIN_HINTS_ITEM_NAME));
  }

  async getAuthHintsSession(ui_locales) {
    return new Promise((resolve) => {
      const authHints = this.getAuthHints();

      // making sure authhints contain the login hint and still valid before performing the silent login
      if (
        authHints &&
        authHints.login_hint &&
        new Date(authHints.timestamp).getTime() + HINTS_EXPIRATION * 1000 >
          new Date().getTime()
      ) {
        const iframe = document.createElement("iframe");
        const [lang, region] = ui_locales.split("-");
        const searchParams = new URLSearchParams({
          redirect_uri: location.href,
          client_id: this.configs.CLIENT_ID,
          ui_locales: `${lang}-${region.toUpperCase()}`,
          prompt: "none",
          login_hint: authHints.login_hint,
        });
        iframe.src = `${location.origin}${
          ENDPOINTS.AUTH
        }?${searchParams.toString()}`;
        console.log("iframe URL", iframe.src);
        iframe.hidden = true;
        const timeoutID = setTimeout(() => {
          console.log("silent login timed out");
          resolve(false);
        }, this.configs.SSO_TIMEOUT || DEFAULT_SSO_TIMEOUT);
        window.addEventListener("message", (event) => {
          if (event?.data === "success") {
            clearTimeout(timeoutID);
            document.body.removeChild(iframeElement);
            resolve(
              JSON.parse(localStorage.getItem(this.configs.SESSION_ITEM_NAME))
            );
          } else if (event?.data === "failure") {
            clearTimeout(timeoutID);
            document.body.removeChild(iframeElement);
            console.log("silent login failed");
            resolve(false);
          }
        });
        const iframeElement = document.body.appendChild(iframe);
      } else {
        resolve(false);
      }
    });
  }

  /**
   * @name getSession
   * @description Function that fetches the user session info based on SSO logic
   * @param {string} ui_locales
   *
   * @returns {Promise} session data
   */
  async getSession(ui_locales) {
    return new Promise((resolve) => {
      (async () => {
        if (this.getLocalSession(resolve)) {
          return;
        }
        const session = await this.getAuthHintsSession(ui_locales);
        if (session) {
          resolve(session);
          return;
        }
        resolve(false);
      })();
    });
  }
}
