export const toggleLogin = (
  loginElement,
  logoutElement,
  profileElement,
  nvodSession
) => {
  if (nvodSession) {
    loginElement.style.display = "none";
    logoutElement.style.display = "block";
    profileElement.style.display = "block";
  } else {
    loginElement.style.display = "block";
    logoutElement.style.display = "none";
    profileElement.style.display = "none";
    localStorage.removeItem(SESSION_ITEM_NAME);
    localStorage.removeItem(KOLTURA_SESSION_LOADED_ITEM_NAME);
  }
};
